import NotificationModel from '@/Modules/Notification/NotificationModel.js'

export const NotificationAction = Object.freeze({
  show: 'show',
  // More actions depends on your needs...
})

export default class NotificationManager {
  static handle(notification, args = null) {
    if (!(notification instanceof NotificationModel)) {
      console.warn(
        'Please pass NotificationModel variable to NotificationManager.handle().'
      )
      return
    }

    const action = _.get(notification, 'data.meta.action')

    if (!action) {
      console.warn(`Notification ${notification.id} doesn't have action set.`)
      return
    }

    if (!_.has(NotificationActionMap, action)) {
      console.warn(
        `Action: ${action} not found. Please add ${action} to NotificationActionMap.`
      )
      return
    }

    const actionClass = _.get(NotificationActionMap, action)(notification, args)

    actionClass.handle()
  }
}

const lazyLoadAction = (importedClass, notification, args) =>
  new importedClass.default(notification, args)

/**
 * Necessary actions like 'show'
 *
 * If you need some custom logic for specific resource for ex.: (open and recalculate) - create
 * a different Action model which handles that (ex.: NotificationQuoteOpenAndRecalculateAction)
 */
export const NotificationActionMap = Object.freeze({
  [NotificationAction.show]: (notification, args = null) =>
    lazyLoadAction(
      import('@/Modules/Notification/Actions/NotificationShowAction.js'),
      notification,
      args
    ),
  // More actions depends on your needs...
})
